<template>
  <div class="container">
    <div style="margin: 0 auto">
      <transition  name="fade" @after-leave="countdownComplete">
        <!-- Use v-if and v-else to conditionally render either the countdown or the "Countdown Complete!" message -->
        <h1 class="transition-header text-center" v-if="countdown > 0">Time Left: {{ minute + ':' + second}}</h1>
      </transition>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h1>{{ startWord }}</h1>
        <div class="error-message" v-if="errorMessage">
          {{ errorMessage }}
        </div>
        <input type="text" v-model="newWord" @keyup.enter="addWord">
        <button class="btn-primary btn" @click="addWord">Add Word</button>
      </div>
      <ul class="list-group col-md-6 col-sm-12 text-center">
        <li class="list-group-item" v-for="(word, index) in words" :key="index">{{ word }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import WordList from '@/resources/WordList';
export default {
  name: 'WordList',
  props: {
    difficulty: {
      type: String,
      required: false
    },
    endGame: {
      type: Function,
      required: true
    },
    startWord: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      wordList: new WordList,
      words: [],
      newWord: null,
      countdown: 60,
      minute: '',
      second: '',
      errorMessage: ''
    };
  },
  watch: {
    countdown(time) {
      this.updateTime(time);
    },
    startWord(startWord) {
      if (startWord) {
        this.updateTime(this.countdown);
        this.countDown();
      }
    },
  },
    methods: {
      addWord() {
        this.newWord = this.newWord.toLowerCase();
        this.errorMessage = '';
        if (!this.newWord) {
          this.errorMessage = 'Please Enter a Word';
        } else if (!this.wordList.search(this.newWord)) {
          this.errorMessage = 'Word is not in the dictionary';
        } else if (!this.containsLetters()) {
          this.errorMessage = 'Word does not contain all the letters of the original word';
        } else if (this.words.includes(this.newWord)) {
          this.errorMessage = 'Word is already in the list';
        } else {
          this.words.push(this.newWord);
        }
        this.newWord = null;
      },
      containsLetters() {
        const startWord = this.startWord.toLowerCase().split("");
        const newWord = this.newWord.toLowerCase().split("");

        for (let i = 0; i < newWord.length; i++) {
          const char = newWord[i];
          const index = startWord.indexOf(char);
          if (index !== -1) {
            startWord.splice(index, 1);
          }
        }

        return startWord.length === 0;
      },
      countDown() {
        this.countdownInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            // If the countdown reaches 0, run the countdownComplete method and clear the interval
            clearInterval(this.countdownInterval);
          }
        }, 1000);
      },
      countdownComplete() {
        this.endGame(this.words);
        Object.assign(this.$data, this.$options.data());
      },
      updateTime(time) {
        const minutes = Math.floor((time % 3600) / 60);
        const remainingSeconds = Math.floor(time % 60);
        this.minute = minutes.toString().padStart(2, '0');
        this.second = remainingSeconds.toString().padStart(2, '0');
      },
    },
  }

</script>

<style>

.btn-primary {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* Define the styles for the fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 767px) {
  .list-group {
    padding: 0;
    height: 25vh;
    overflow-y: scroll;
  }
}

.transition-header {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-message {
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style>
