const fullList = require('../assets/word_list.json');
const mostCommon = require('../assets/most_common_words.json');

class WordList {

    search(key) {
        const firstLetter = key[0];
        const secondLetter = key[1];
        const thirdLetter = key[2];
        const wordList = JSON.parse(fullList);

        return (
        firstLetter in wordList
        && secondLetter in wordList[firstLetter]
        && thirdLetter in wordList[firstLetter][secondLetter]
        && wordList[firstLetter][secondLetter][thirdLetter].includes(key)
        )
    }
    randomWord(difficulty) {
        let difficultyList;
        let shuffle = false;
        switch (difficulty) {
            case 'Easy':
                difficultyList = 'short';
                break;
            case 'Easy+':
                difficultyList = 'short';
                shuffle = true;
                break;
            case 'Hard':
                difficultyList = 'long';
                break;
            case 'Hard+':
                difficultyList = 'long';
                shuffle = true;
                break;
            default:
                difficultyList = 'short';
                break;
        }
        let returnWord = mostCommon[difficultyList][Math.floor(Math.random() * mostCommon[difficultyList].length)];
        if (shuffle) {
            returnWord = this.shuffleWord(returnWord);
        }
        return returnWord;
    }

    shuffleWord(word) {
        let shuffledWord = '';
        while (word.length > 0) {
            const randomIndex = Math.floor(Math.random() * word.length);
            shuffledWord += word[randomIndex];
            word = word.slice(0, randomIndex) + word.slice(randomIndex + 1);
        }
        return shuffledWord;
    }

    addWords(word) {
        const wordList = JSON.parse(fullList);

        let searchList = this.shuffleArray([word[0], word[1], word[2]]);
        let returnList = [];
        for (let list in searchList) {
            const firstLetter = searchList[list][0];
            const secondLetter = searchList[list][1];
            const thirdLetter = searchList[list][2];
            if(firstLetter in wordList
            && secondLetter in wordList[firstLetter]
            && thirdLetter in wordList[firstLetter][secondLetter]) {
                const checkWordList = wordList[firstLetter][secondLetter][thirdLetter]
                for (let checkWord in checkWordList) {
                    if (this.containsLetters(word, checkWordList[checkWord])) {
                        returnList.push(checkWordList[checkWord]);
                    }
                }
            }
        }
        return returnList;
    }

    shuffleArray(arr) {
        let result = [];

        const permute = (arr, m = []) => {
            if (arr.length === 0) {
                result.push(m);
            } else {
                for (let i = 0; i < arr.length; i++) {
                    let curr = arr.slice();
                    let next = curr.splice(i, 1);
                    permute(curr.slice(), m.concat(next));
                }
            }
        }
        permute(arr);
        return result;
    }

    containsLetters(start_word, new_word) {
        const startWord = start_word.toLowerCase().split("");
        const newWord = new_word.toLowerCase().split("");

        for (let i = 0; i < newWord.length; i++) {
            const char = newWord[i];
            const index = startWord.indexOf(char);
            if (index !== -1) {
                startWord.splice(index, 1);
            }
        }

        return startWord.length === 0;
    }

}

module.exports = WordList;