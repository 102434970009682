<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="center">Branchd (Alpha)</h1>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12 d-flex justify-content-md-center justify-content-sm-center justify-content-center flex-wrap">
        <div class="btn-group rules" role="group" >
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#game-rules">How To Play</button>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12 d-flex justify-content-md-center justify-content-sm-center justify-content-center flex-wrap">
        <div class="btn-group" role="group" v-for="(button, index) in buttonLabels" :key="index">
          <button type="button" class="btn btn-primary w-100" @click="handleButtonClick(index)">{{ button }}</button>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="game-rules" tabindex="-1" aria-labelledby="game-rules-label" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="game-rules-label">How To Play</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-left">
            <p>Branchd is a game about words. How well do you know them and how well do you know what they contain.</p>

              <p>In this game, you will be presented with a starting word. Your goal is to write out as many words as possible
                that contain all the letters of the starting word. The word can be an anagram, or it could be longer than the
                start word, the only rule is that all the letters, including multiple uses of the same letter, of the
                original word have to be contained in every word you write.</p>

              Rules of the game:
              <ol>
                <li> Select a difficulty. Easy words will contain between 4-5 characters. Hard will contain between 6-7 characters.
                  The "+" version of each will present a random assortment of characters of that difficulties length.
                  Every starting word or assortment of letters will have at least 10 possible words it can match with.</li>
                <li> After the 5 second countdown, the start word will be presented and the 1 minute countdown will start.
                  At that moment, you will have 1 minute to write as many words as you can think of that contain all the
                  letters of the start word. Only words that are in the dictionary and that contain all the letters of the
                  start word will be added to the list.</li>
                <li> After the 1 minute is over, your score will be calculated and you can share it with your friends to see
                  who knows the most words!</li>
              </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'MainMenu',
  props: {
    selectDifficulty: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      heading: 'Welcome to Branchd',
      buttonLabels: ['Easy', 'Easy+', 'Hard', 'Hard+'],
      difficulty: "",
      isModalVisible: false
    };
  },
  methods: {
    handleButtonClick(buttonIndex) {
      this.difficulty = this.buttonLabels[buttonIndex];
      this.selectDifficulty(this.difficulty);
      this.$gtm.trackEvent({
        event: 'difficulty', // Event type [default = 'interaction'] (Optional)
        category: 'Difficulty',
        action: 'select',
        label: this.difficulty,
      });
    },
    showModal() {
      this.isModalVisible = true;
    },
    hideModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style>
.container {
  text-align: center;
}
.btn-primary {
  margin: 0 3rem;
}
.text-left {
  text-align: left !important;
}
.modal-body.text-left ol {
  counter-reset: item;
  list-style-type: none;
}

.modal-body.text-left li:before {
  content: counter(item) ") ";
  counter-increment: item;
}
@media (max-width: 767px) {
  .btn-group {
    width: 50%;
  }
  .btn-group.rules {
    width: 90%;
  }
  .btn-primary {
    width: 75%;
  }
  .btn {
    padding: 1rem 3rem;
    margin-left: 1rem;
    margin-right: 2rem;
  }
}
@media (min-width: 768px) {
  .btn-group {
    width: 25%;

  }
  .btn-group.rules {
    width: 50%;
  }
}


</style>
