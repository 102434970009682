<template>
  <div>
    <!-- Add the transition component with the "fade" name -->
    <transition name="fade" @after-leave="countdownComplete">
      <!-- Use v-if and v-else to conditionally render either the countdown or the "Countdown Complete!" message -->
      <h1 v-if="countdown > 0">Starts in: {{ countdown }}</h1>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    startCountdown: {
      type: Boolean,
      required: true
    },
    startGame: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      countdown: 5
    };
  },
  watch: {
    startCountdown(newValue) {
        this.countDown(newValue);
        if (!newValue) {
          this.countdown = 5;
        }
    }
  },
  methods: {
    countdownComplete() {
      this.startGame();
      Object.assign(this.$data, this.$options.data());
    },
    countDown(newValue) {
      if (newValue)
      this.countdownInterval = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          // If the countdown reaches 0, run the countdownComplete method and clear the interval
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    }
  },
};
</script>

<style>
/* Define the styles for the fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
