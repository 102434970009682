<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="center">Results From Game</h1>
      </div>
    </div>
    <div class="row align-items-center">
      <div v-if="words.length > 0" class="col score">
        Your Score is {{score}}
      </div>
      <div v-if="words.length === 0" class="col score">
        Unfortunately, you were not able to add any words. Try again.
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-md-center justify-content-sm-center justify-content-center flex-wrap">
        <button class="btn btn-primary" @click="restartGame">Play New Game</button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'MainMenu',
  props: {
    words: {
      type: Array,
      required: true
    },
    shouldCalculateScore: {
      type: Boolean,
      required: true
    },
    restartGame: {
      type: Function,
      required: true
    },
    difficulty: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      score: 0
    };
  },
  watch: {
    shouldCalculateScore(score) {
      if (score) {
        this.calculateScore();
      } else {
        this.resetScore();
      }
    }
  },
  methods: {
    calculateScore() {
      let finalScore = 0;
      const letterFrequency = [
        "e",
        "t",
        "a",
        "o",
        "i",
        "n",
        "s",
        "r",
        "h",
        "l",
        "d",
        "c",
        "u",
        "m",
        "f",
        "p",
        "g",
        "w",
        "y",
        "b",
        "v",
        "k",
        "x",
        "j",
        "q",
        "z"
      ];
      for (let word in this.words) {
        const wordCharacters = this.words[word].split('')
        for (let char in wordCharacters) {
          char = wordCharacters[char];
          finalScore += ((letterFrequency.indexOf(char) + 1) * 2)
        }
      }
      let multiplier = 1;
      switch (this.difficulty) {
        case 'Easy+':
          multiplier = 2;
          break;
        case 'Hard':
          multiplier = 2;
          break;
        case 'Hard+':
          multiplier = 4;
          break;

      }
      this.score = finalScore * multiplier;
    },
    resetScore() {
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>

<style>
.container {
  text-align: center;
}

.score {
  margin-top: 2rem;
  font-size: 2rem;
}


</style>
