<template>
  <MainMenu v-show="isMenuVisible" :selectDifficulty="selectDifficulty" />
  <CountdownTimer v-show="isCountdownVisible"
                  :startCountdown="isCountdownVisible"
                  :startGame="startGame"
  />
  <WordListComponent :end-game="endGame"
            :difficulty="difficulty"
            :start-word="startWord"
            v-show="isShowWordList"
  />
  <EndScreen :words="words"
             :shouldCalculateScore="isShowEndScreen"
             v-show="isShowEndScreen"
             :difficulty="difficulty"
             :restart-game="restartGame"/>
</template>

<script>
import CountdownTimer from "@/components/CountdownTimer.vue";
import MainMenu from "@/components/MainMenu.vue";
import WordListComponent from "@/components/WordList.vue";
import EndScreen from "@/components/EndScreen.vue";
import WordList from "@/resources/WordList";

export default {
  name: 'GameController',
  components: {
    MainMenu,
    WordListComponent,
    CountdownTimer,
    EndScreen
  },
  data() {
    return {
      startWord: null,
      isMenuVisible: true,
      isCountdownVisible: false,
      isShowWordList: false,
      isShowEndScreen: false,
      difficulty: null,
      words: [],
      wordList: new WordList
    }
  },
  methods: {
    selectDifficulty(difficulty) {
      this.difficulty = difficulty;
      this.isMenuVisible = false;
      this.isCountdownVisible = true;
    },
    startGame(){
      this.createStartWord();
      this.isCountdownVisible = false;
      this.isShowWordList = true;
    },
    endGame(words){
      this.words = words;
      this.isShowWordList = false;
      this.isShowEndScreen = true
    },
    createStartWord() {
      this.startWord = this.wordList.randomWord(this.difficulty);
    },
    restartGame() {
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>

<style scoped>
.game-controller {
  display: flex;
  justify-content: space-between;
}
</style>